












































































































































.v-input {
  margin-top: 0;
}
.dr {
  &-title {
    color: #4a5275;
    font-size: 19px;
  }
}
.content-filtros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.filtros{
  &-graficos {
    input {
      display: none;
    }
    label {
      cursor: pointer;
      padding: 0 15px;
      color: #757575;
      font-size:16px
    }
    input:checked + label {
      color: #1cb4ff;
      font-weight: bold;
      font-size:16px
    }
  }
}
.loader{
  position: absolute;
  top: 200px;
  z-index: 99999;
}
.table::v-deep .v-data-table .v-data-table__wrapper table tbody tr{
  cursor: pointer;
}

